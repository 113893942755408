<template>
  <el-row type="flex" justify="center" align="middle" :style="{ flex: 1 }">
    <el-col :xs="24" :sm="18" :md="12" :lg="8" :xl="8">
      <el-card class="box-card login-card" v-loading="loading">
        <el-row
          type="flex"
          justify="center"
          :style="{ 'margin-bottom': '22px' }"
        >
          <el-col :xl="24" style="text-align: center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logo"
              class="login-logo"
            ></svg>
          </el-col>
        </el-row>
        <el-form @submit.native.prevent="login">
          <el-form-item
            :error="
              form.errors.first('email') ? form.errors.first('email') : null
            "
          >
            <el-input
              v-model="user.email"
              type="email"
              placeholder="Email"
              @focus="form.errors.clear('email')"
              v-input-focus
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="user.password"
              type="password"
              placeholder="Password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              plain
              medium
              native-type="submit"
              :style="{ width: '100%' }"
            >
              Login
            </el-button>
          </el-form-item>
        </el-form>
        <router-link
          class="el-button forgot-password-button el-button--text"
          :to="{ name: 'forgot_password' }"
        >
          Forgot your password?
        </router-link>
      </el-card>
    </el-col>
  </el-row>
</template>

<style>
.login-logo {
  max-height: 150px;
  fill: rgba(212, 161, 0, 0.4);
  transition: fill 0.5s ease;
  margin-bottom: 15px;
}

.login-card:hover .login-logo {
  fill: rgba(212, 161, 0, 0.9);
}

.forgot-password-button {
  padding: 0;
  text-align: center;
  width: 100%;
}
</style>

<script>
import Form from "form-backend-validation"

import { mapActions } from "vuex"

import svg from "@/theme/svg"

export default {
  name: "Login",

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      form: new Form(),
      logo: svg.comet,
      loading: false,
    }
  },

  methods: {
    ...mapActions({
      update: "auth/update",
    }),
    login() {
      this.loading = true
      this.$auth.login({
        data: {
          email: this.user.email,
          password: this.user.password,
        },
        success: () => {
          this.loading = false
          this.update(this.$auth.user())
        },
        redirect: this.redirect,
        error: (resp) => {
          this.loading = false
          if (
            resp.response &&
            [422, 423].indexOf(resp.response.status) !== -1
          ) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        },
        rememberMe: true,
        fetchUser: true,
      })
    },
  },

  created() {
    let redirect = this.$auth.redirect()
    if (redirect) {
      this.redirect = redirect.from.fullPath
    } else if (this.$cookie.get("last_page")) {
      this.redirect = this.$cookie.get("last_page")
    } else {
      this.redirect = "/"
    }
  },

  mounted() {
    if (typeof this.$route.query.registered !== "undefined") {
      this.$message({
        type: "success",
        message: "Registration successful! Please login below.",
        duration: 5000,
      })
    } else if (typeof this.$route.query.reset !== "undefined") {
      this.$message({
        type: "success",
        message: "Password reset successful! Please login below.",
        duration: 5000,
      })
    }
  },
}
</script>
